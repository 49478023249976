import React from "react"
import styled from "styled-components"
import { Container } from "@material-ui/core"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ForgotPasswordForm from "../../components/forgot-password-form"
import PageTitle from "../../components/page-title"
import lockOpen from "../../images/lock-open.svg"
import { breakpoints } from "../../components/variables"

const RequestDemoW = styled.div``
const RequestDemoIW = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column-reverse;
  }
`

const ForgotPassword = () => {
  return (
    <Layout>
      <SEO title="Forgot Password" />
      <PageTitle title="Forgot Password" icon={lockOpen} />
      <RequestDemoW>
        <Container>
          <RequestDemoIW>
            <ForgotPasswordForm />
          </RequestDemoIW>
        </Container>
      </RequestDemoW>
    </Layout>
  )
}

export default ForgotPassword
